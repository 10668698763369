<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">土壤墒情</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    label="设备"
                    prop="SBID"
                    v-if="formdata.dataDic.SFFSBH != 0"
                >
                    <!-- <el-select
                        v-model="formdata.dataDic.SBID"
                        placeholder="请选择设备"
                        :disabled="true"
                    >
                        <el-option
                            v-for="(item, index) in SbList"
                            :key="`${item.ID}-${index}`"
                            :label="item.SBMC"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select> -->
                    <el-input
                        v-model="formdata.dataDic.SBMC"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="PH" prop="PH">
                    <el-input
                        v-model="formdata.dataDic.PH"
                        placeholder="请输入"
                        @input="toDecimal($event, 'SD')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="温度" prop="WD">
                    <el-input
                        v-model="formdata.dataDic.WD"
                        placeholder="请输入温度"
                        @input="toDecimal($event, 'WD')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">℃</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="水分" prop="SF">
                    <el-input
                        v-model="formdata.dataDic.SF"
                        placeholder=""
                        @input="toDecimal($event, 'SF')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">%</template>
                    </el-input>
                </el-form-item>

                <el-form-item label="电导率" prop="DDL">
                    <el-input
                        v-model="formdata.dataDic.DDL"
                        placeholder="请输入"
                        @input="toDecimal($event, 'SD')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">S/m</template>
                    </el-input>
                </el-form-item>

                <el-form-item label="时间" prop="JCSJ">
                    <el-date-picker
                        v-model="formdata.dataDic.JCSJ"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime"
                        :disabled="!isEdit"
                        placeholder="请选择时间"
                    ></el-date-picker>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../../0_com_js/info_mixin.js";
export default {
    name: "zr_trsq",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "8020504",
                dataDic: {
                    SBMC: "",
                    JCSJ: "",
                    JCZ: "",
                    WD: "",
                    SF: "",
                },
            },
            SGLXList: [],
            SbList: [],
        };
    },
    mounted() {
        this.getSbList();
    },
    methods: {
        ...mapActions(["GetGLXList"]),
        async getSbList() {
            let res = await this.GetGLXList({
                itemcode: "sbqd",
                lm: "14",
            });
            this.SbList = res.ResultValue || [];
        },
    },
};
</script>

<style>
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
</style>
